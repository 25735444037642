@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&family=Montserrat:wght@200;300;400;500&display=swap');

/* transition */
$anime: 0.3s ease;

$anime-mid: 1s ease;

/* font family*/
$zen: 'Zen Kaku Gothic New', sans-serif;
$barlow: 'Barlow', sans-serif;
$ryo: 'ryo-gothic-plusn', sans-serif;
$mon: 'Montserrat', sans-serif;


/* colors */
$black: #000;

$gray: #707070;
$gray-dark: #5d5d5d;
$gray-ash: #f0f0f0;
$gray-clear:#f1f1f1;
$gray-thin: #e8e8e8;
$gray-pale: #f2f0e9;
$gray-light: #c1c1c1;
$gray-light2: #cbcbcb;


$orange: #fa8b43;
$orange02: #ff8b43;
$orange03: #ed891e;
$orange-deep: #e75a24;
$blue-pale: #f2f5f8;
$yellow: #fad300;
$yellow-light: #fed200;
$yellow-deep: #ffd900;
$yellow-pale: #f8f7f2;
$yellow-ash: #eae5cc;
$white: #fff;

$orange-pale: #fef6cc;
$red: #d21000;
$beige-clear: #faf6ef;



/*animation*/
@keyframes spNavBtnTopOpn {
    0% {
        margin-bottom: 10px;
    }
    50% {
        margin-bottom: 5px;
        transform: rotate(0);
    }
    100% {
        margin-bottom: 5px;
        transform: rotate(25deg);
    }
}

@keyframes spNavBtnBtmOpn {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: -6px;
        transform: rotate(0);
    }
    100% {
        margin-top: -6px;
        transform: rotate(-25deg);
    }
}

@keyframes spNavBtnTopCls {
    0% {
        margin-bottom: 5px;
        transform: rotate(25deg);
    }
    50% {
        margin-bottom: 5px;
        transform: rotate(0);
    }
    100% {
        margin-bottom: 10px;
        transform: rotate(0);
    }
}

@keyframes spNavBtnBtmCls {
    0% {
        margin-top: -6px;
        transform: rotate(-25deg);
    }
    50% {
        margin-top: -6px;
        transform: rotate(0);
    }
    100% {
        margin-top: 0;
        transform: rotate(0);        
    }
}

@keyframes ripples {
    0% {
      -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1
    }
    75% {
      opacity: 0
    }
    100% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0
    }
  }

@keyframes scroll-line{
    0%{
        top: -80px;
    }
    50% {
        opacity: 1;
    }
    100%{
        top: 100%;
        opacity: 0;
    }
}

@keyframes passing-bar{
	0% {
		left: 0;
		width: 0;
	}
	50% {
		left: 0;
		width: 100%;
	}
	51% {
		left: 0;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 0;
	}
}

@keyframes passing-txt{
	0% { opacity:0; }
	50% { opacity:0; }
	100% { opacity:1; }
}

@keyframes fadezoom {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.1);
        /* 拡大率 */
    }
}