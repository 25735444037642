@mixin pc($width: 1025px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tb {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin touch {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin min-sp($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}



/*font*/
@mixin fontSize($fizeSize) {
  font-size: $fizeSize/16 + rem;
}


@mixin lineHeight($lineHeight,$fizeSize) {
  line-height: $lineHeight/$fizeSize;
}

@mixin letterSpacing($tracking) {
  letter-spacing: $tracking/1000 + rem;
}

/*other*/

@mixin hover {
  &:hover {
    transition: $anime;
    opacity: 0.6;
  }
}